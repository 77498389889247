.gButton {
    background-color:white;
    border-radius:2px;
    cursor:pointer;
    font-size:14px;
    height:36px;
    border:0.5px solid #f0f0f0;
    box-shadow:0 1px 1px 0 #222222;
    padding: 0 36px;
}
.gButton div {
    margin:auto;
    display:flex;
    align-items:center;
}
.gButton svg {
    margin-right:4px;
}
.gButton span {
    display:inline-block;
    margin-top:2px;
}